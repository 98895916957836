import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn : 'root'
})
export class FilterService {
  filterForm: FormGroup = this._fb.group({
    firstName : '',
    minAge: 18,
    maxAge: 60,
    cities: [],
    genders: [],
  });

  city : FormControl = new FormControl();

  constructor(private _fb: FormBuilder) {
    this.filterForm.valueChanges.subscribe(x =>{
      console.log(x);
    })
  }
}